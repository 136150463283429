/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */
import React from 'react';

class QRCodeComponent extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        qrcode: null
      };

      this.qrcoderef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            qrcode: new window.QRCode(this.qrcoderef.current, this.props)
        });
    }

    componentWillUnmount() {
        this.state.qrcode.clear();
    }

    render() {
        return <div ref={this.qrcoderef} className="qr-container" />;
    }
  };

  QRCodeComponent.defaultProps = {
    text: '',
    width: 256,
    height: 256,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: window.QRCode.CorrectLevel.M,
  };

export default QRCodeComponent;
