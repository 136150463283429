/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './polyfills';
import '@microfocus/ux-ias/dist/ux-ias.css';
import '@microfocus/ias-icons/dist/ias-icons.css';
import './index.scss';
import App from './components/App';
//TODO redux package should be replace by @reduxjs/toolkit.
//Why we should use redux toolkit: https://redux.js.org/introduction/why-rtk-is-redux-today
import {applyMiddleware, legacy_createStore as createStore } from 'redux';
import {Provider} from 'react-redux';
import React from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import {loadStorageItem, storageItems} from './utils/local-storage';
import {loadLoginSession} from './actions/authentication.actions';
import * as types from './actions/action-types';

// Use redux-logger only if specified in environment. Code here that will not be used is excluded from build via
// Webpack define plugin - see https://webpack.js.org/plugins/define-plugin/#usage
let middleware = null;
if (process.env.REACT_APP_LOGGING) {
    const logger = require('redux-logger').createLogger({collapsed: true});
    middleware = applyMiddleware(thunk, logger);
}
else {
    middleware = applyMiddleware(thunk);
}

const store = createStore(rootReducer, middleware);
const isSSOLogout = window.location.pathname.indexOf('sso_logout') !== -1;

const loginSessionId = loadStorageItem(storageItems.LOGIN_SESSION_ID);
if (loginSessionId) {
    loadLoginSession(loginSessionId, isSSOLogout)(store.dispatch, store.getState);
}
else {
    store.dispatch({type: types.CLEAR_USER_INFO});
}

//React 18 has been used to setup the App.
const container = document.getElementById('root');
const appRoot = createRoot(container);
appRoot.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
